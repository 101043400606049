<template>
  <el-upload
    ref="uploader"
    v-bind="$attrs"
    :before-upload="beforeUpload"
    :http-request="httpRequest"
    :on-success="onSuccess"
    :on-remove="onRemove"
    :on-exceed="onExceed"
  >
    <template v-slot:trigger>
      <slot />
    </template>
    <template v-slot:tip>
      <slot name="tip" />
    </template>
  </el-upload>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
// import md5 from 'blueimp-md5';

export default {
  name: 'McoUpload',
  // props:{
  //   fileList:{
  //     type:Array,
  //     default:() => {
  //       return [];
  //     }
  //   }
  // },
  data() {
    return {
      file: {},
      nameMap: {},
      iserror: false,
    };
  },
  computed: {
    ...mapState('img', ['policy']),
    uploader() {
      return this.$refs.uploader;
    },
  },
  methods: {
    onSuccess(response, file, fileList) {
      this.done(fileList);
    },
    onRemove(file, fileList) {
      this.done(fileList);
    },
    onExceed() {
      this.$message.error('文件超出个数限制');
    },
    async beforeUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png' ||
        file.type === 'image/pdf';
      // const isJPG = file.type.includes('image');
      if (!isJPG) {
        this.$message.error('上传文件必须为图片类型!');
        return false;
      }

      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 50MB!');
        return false;
      }
      this.file = file;
      this.nameMap[file.name] = `${new Date().getTime()}.${this.getFileExt(
        file.name
      )}`;
      // try {
      //   await this.getImgSign();
      // } catch (error) {
      //   this.$message.error(error.message);
      //   this.iserror = true;
      //   return false;
      // }
      console.log(file);
      return true;
    },
    httpRequest() {
      const { policy: data = {}, file } = this;

      const len = Object.keys(file).length;
      if (!len) {
        this.clearFiles();
        return false;
      }

      if (this.iserror) {
        this.clearFiles();
        this.iserror = false;
        return false;
      }
      console.log('1111111111111111111111111 data:', data);
      const form = new FormData();
      // form.append('signature', `${data.signature}`);
      // form.append('OSSAccessKeyId', `${data.accessId}`);
      // form.append('success_action_status', '200');
      // form.append('policy', `${data.policy}`);
      // form.append('key', `${data.dir}/${nameMap[file.name]}`);
      form.append('file', file);
      console.log('1111111111111111111111111 form:', form);

      return axios.post(
        `${
          window.SITE_CONFIG['apiURL']
        }/sys/oss/upload?token=${sessionStorage.getItem('token')}`,
        form,
        // 不能携带浏览器信息
        { withCredentials: false }
      );
    },
    ...mapActions('img', ['getImgSign']),
    done(files) {
      // const { policy: { host, dir } = {}, nameMap } = this;
      const urls = files.map((i) => i.response.data.data.src);

      this.$emit('done', urls);
    },
    clearFiles() {
      this.uploader.clearFiles();
    },
    getFileExt(name) {
      const tmps = name.split('.');
      return tmps[1];
    },
    // transFileName(name) {
    //   const tmps = name.split('.');
    //   return `${md5(tmps[0])}.${tmps[1]}`;
    // },
  },
};
</script>
