<template>
  <div class="formdata">
    <el-form ref="form" :model="form" :rules="rules" label-width="130px">
      <div class="title">
        <div class="titleType">医生基本信息</div>
      </div>
      <el-row :gutter="60">
        <el-col :span="10">
          <el-row>
            <el-form-item label="姓名:" prop="doctorName">
              <el-input
                v-model="form.doctorName"
                v-check-permission
                placeholder="请输入医生姓名"
                style="width: 65%"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="所属医生团队:" prop="teamCode">
              <!-- {{ form.teamName }} -->
              <el-select v-model="form.teamCode">
                <el-option
                  v-for="item in doctorTeamList"
                  :key="item.teamCode"
                  :value="item.teamCode"
                  :label="item.teamName"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="身份证号:" prop="idCard">
              <el-input
                v-model="form.idCard"
                v-check-permission
                placeholder="请输入身份证号"
                style="width: 65%"
                :disabled="isView"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="就职医院:" prop="orgCode">
              <el-select v-model="form.orgCode">
                <el-option
                  v-for="item in allOrganList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :span="10">
          <el-row>
            <el-form-item label="职称:" prop="professionalTitleCode">
              <el-select
                v-model="form.occupationCode"
                style="width: 45%"
                @change="receiveProfessionList"
              >
                <el-option
                  v-for="item in occupationList"
                  :key="item.occupationCode"
                  :value="item.occupationCode"
                  :label="item.occupationName"
                />
              </el-select>
              <el-select
                v-if="form.occupationCode"
                v-model="form.professionalTitleCode"
                style="width: 45%"
              >
                <el-option
                  v-for="item in professionalList"
                  :key="item.professionalTitleCode"
                  :label="item.professionalTitleName"
                  :value="item.professionalTitleCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="科室:" prop="deptName">
              <el-select
                v-model="form.parentDeptCode"
                placeholder="请选择科室"
                filterable
                style="width: 45%"
                @change="receiveDeptmentList"
              >
                <el-option
                  v-for="item in parentDepartmentList"
                  :key="item.deptCode"
                  :value="item.deptCode"
                  :label="item.deptName"
                />
              </el-select>
              <el-select
                v-if="form.parentDeptCode !== ''"
                v-model="form.deptName"
                placeholder="请选择专业"
                filterable
                style="width: 45%"
                @change="changeDeptName"
              >
                <el-option
                  v-for="item in departmentList"
                  :key="item.deptCode"
                  :label="item.deptName"
                  :value="item.deptName"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="联系方式:" prop="mobile">
              <el-input
                v-model="form.mobile"
                v-check-permission
                placeholder="请输入联系方式"
                style="width: 65%"
                :disabled="isView"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医师第一执业机构名称:">
              <el-select v-model="form.regulatory.workInstCode">
                <el-option
                  v-for="item in allOrganList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :span="4">
          <el-row>
            <div class="img">
              <div>头像:</div>
              <do-upload
                ref="doupload"
                :action="''"
                :show-file-list="false"
                :file-list="fileList"
                @done="(vals) => ondone(vals, 'photo')"
              >
                <img v-if="form.photoUrl" :src="form.photoUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </do-upload>
            </div>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="60">
        <el-col :span="10">
          <el-row>
            <!-- 监管 -->
            <el-form-item label="执业证号:">
              <el-input
                v-model="form.regulatory.pracNo"
                v-check-permission
                placeholder="请输入执业证号"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="资格证号:">
              <el-input
                v-model="form.regulatory.certNo"
                v-check-permission
                placeholder="请输入资格证号"
              />
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="职称证号:">
              <el-input
                v-model="form.regulatory.titleNo"
                v-check-permission
                placeholder="请输入职称证号"
              />
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="医师执业级别:">
              <el-select v-model="form.regulatory.pracLevel">
                <el-option
                  v-for="item in doctorPracticeLevelList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="是否同意以上条款:">
              <el-select v-model="form.regulatory.agreeTerms">
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="签约医疗机构名称:">
              <el-select v-model="form.regulatory.orgCode">
                <el-option
                  v-for="item in firstPracticeOrganList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医师在机构执业的有效期开始时间:">
              <el-date-picker
                v-model="form.regulatory.docMultiSitedDateStart"
                value-format="yyyy-MM-dd"
                type="date"
                style="width: 90%"
                placeholder="选择开始日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="申请拟执业医疗机构意见:">
              <el-input
                v-model="form.regulatory.hosOpinion"
                v-check-permission
                placeholder="请输入申请拟执业医疗机构意见                                                                                     "
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医院是否已确认:">
              <el-select v-model="form.regulatory.hosConfirmFlag">
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="性别:">
              <el-select v-model="form.regulatory.geCode">
                <el-option
                  v-for="item in sexList"
                  :key="item.geCode"
                  :label="item.geName"
                  :value="item.geCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="出生日期:">
              <el-date-picker
                v-model="form.regulatory.docBirthdate"
                value-format="yyyy-MM-dd"
                type="date"
                style="width: 90%"
                placeholder="选择出生日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="学历:">
              <el-select v-model="form.regulatory.docEdu">
                <el-option
                  v-for="item in educationList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="行政职务:">
              <el-input
                v-model="form.regulatory.docPosition"
                v-check-permission
                placeholder="请输入行政职务"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="健康状况:">
              <el-select v-model="form.regulatory.healthCondition">
                <el-option
                  v-for="item in healthConditionList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :span="10">
          <el-row>
            <el-form-item label="执业证发证日期:">
              <el-date-picker
                v-model="form.regulatory.pracRecDate"
                value-format="yyyy-MM-dd"
                type="date"
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="资格证发证日期:">
              <el-date-picker
                v-model="form.regulatory.certRecDate"
                value-format="yyyy-MM-dd"
                type="date"
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="职称证取得时间:">
              <el-date-picker
                v-model="form.regulatory.titleRecDate"
                type="date"
                value-format="yyyy-MM-dd"
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医师执业类别:">
              <el-input
                v-model="form.regulatory.pracType"
                v-check-permission
                placeholder="请输入执业类别"
              />
            </el-form-item>
          </el-row>
          <el-row style="width: 920px">
            <el-form-item label="医师执业范围:">
              <el-input
                v-model="form.regulatory.pracScopeApproval"
                v-check-permission
                placeholder="请输入医师执业范围,所有执业范围后缀加上“专业”"
                style="width: 50%"
              />
              <!-- 一级科室 -->
              <el-select
                v-model="form.parentDeptCode"
                placeholder="请选择科室"
                filterable
                style="width: 21%"
                @change="changeParentDeptCode"
              >
                <el-option
                  v-for="item in parentDepartmentList"
                  :key="item.deptCode"
                  :value="item.deptCode"
                  :label="item.deptName"
                />
              </el-select>
              <!-- 二级科室 -->
              <el-select
                v-if="form.parentDeptCode !== ''"
                v-model="form.deptName"
                placeholder="请选择专业"
                filterable
                style="width: 21%"
                @change="changeDeptName"
              >
                <el-option
                  v-for="item in departmentList"
                  :key="item.deptCode"
                  :label="item.deptName"
                  :value="item.deptName"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="海南是否已备案:">
              <el-select v-model="form.regulatory.recordFlag">
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医师在机构执业的有效期结束时间:">
              <el-date-picker
                v-model="form.regulatory.docMultiSitedDateEnd"
                value-format="yyyy-MM-dd"
                type="date"
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="申请拟执业医疗机构意见时间:">
              <el-date-picker
                v-model="form.regulatory.hosOpinionDate"
                value-format="yyyy-MM-dd"
                type="date"
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="海南处方开具权是否备案:">
              <el-select v-model="form.regulatory.presRecordFlag">
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="民族:">
              <el-select v-model="form.regulatory.nationCode" filterable>
                <el-option
                  v-for="item in nationList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="家庭地址:">
              <el-select
                v-model="form.regulatory.provinceCode"
                filterable
                style="width: 40%"
              >
                <el-option
                  v-for="item in districtList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
              <el-select
                v-model="form.regulatory.cityCode"
                filterable
                style="width: 40%"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="家庭地址邮编:">
              <el-input
                v-model="form.regulatory.docPostalCode"
                v-check-permission
                placeholder="请输入家庭地址邮编"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="最近两周期医师定期考核是否合格:">
              <el-select v-model="form.regulatory.qualifyOrNot">
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-col>
      </el-row>
      <div style="margin-left: 30px">
        <el-form-item label="业务水平考核机构或组织名称、考核培训时间及结果:">
          <el-input
            v-model="form.regulatory.appraisalContent"
            placeholder="请输入考核培训时间及结果"
            style="width: 50%; margin-right: 20px"
            type="textarea"
            :rows="5"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="何时何地因何种原因受过何种处罚或处分:">
          <el-input
            v-model="form.regulatory.punishContent"
            placeholder="请输入何种处罚或处分"
            style="width: 50%; margin-right: 20px"
            type="textarea"
            :rows="5"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="其它要说明的问题:">
          <el-input
            v-model="form.regulatory.otherContent"
            placeholder="请输入其他问题"
            style="width: 50%; margin-right: 20px"
            type="textarea"
            :rows="5"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
      </div>
      <div class="title">
        <div class="titleType">个人工作经历</div>
      </div>
      <div class="width-96">
        <el-button type="primary" @click="addInfoExperience()">
          新增个人经历信息
        </el-button>
      </div>

      <el-table :data="experienceList" class="width-96" border>
        <el-table-column prop="weDateStart" label="开始时间" align="center" />
        <el-table-column prop="weDateEnd" label="结束时间" align="center" />
        <el-table-column prop="weEmployer" label="单位名称" align="center" />
        <el-table-column
          prop="weTitle"
          label="专业技术职务任职资格"
          align="center"
        />
        <el-table-column prop="weCertifier" label="证明人" align="center" />
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editorData(scope)">
              修改
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form-item label="个人经历信息:">
        <el-input
          v-model="form.regulatory.workExperienceList"
          v-check-permission
          placeholder="请输入个人经历信息"
          style="width: 50%"
        />
      </el-form-item>
      <div class="title">
        <div class="titleType">证件上传</div>
      </div>
      <el-row :gutter="60">
        <el-col :span="20">
          <el-row>
            <el-form-item label="身份证正反面:">
              <div style="display: flex">
                <do-upload
                  ref="doupload"
                  :action="''"
                  :show-file-list="false"
                  :file-list="fileList"
                  @done="(vals) => ondone(vals, 'idCardFrontImage')"
                >
                  <img
                    v-if="form.idCardFrontImageUrl"
                    :src="form.idCardFrontImageUrl"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon" />
                </do-upload>
                <do-upload
                  ref="doupload"
                  :action="''"
                  :show-file-list="false"
                  :file-list="fileList"
                  @done="(vals) => ondone(vals, 'idCardBackImage')"
                >
                  <img
                    v-if="form.idCardBackImageUrl"
                    :src="form.idCardBackImageUrl"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon" />
                </do-upload>
              </div>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医/药师认证照片:">
              <do-upload-jpg
                ref="douploadJpg"
                :action="''"
                :show-file-list="false"
                :file-list="fileList"
                @done="(vals) => ondone(vals, 'docPhoto')"
              >
                <img
                  v-if="form.regulatory.docPhoto"
                  :src="form.regulatory.docPhoto"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </do-upload-jpg>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="最高学历照片:" prop="educationUrl">
              <div style="display: flex">
                <do-upload
                  ref="doupload"
                  :action="''"
                  :show-file-list="false"
                  :file-list="fileList"
                  @done="(vals) => ondone(vals, 'educationImage')"
                >
                  <img
                    v-if="form.educationUrl"
                    :src="form.educationUrl"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon" />
                </do-upload>
              </div>
            </el-form-item>
          </el-row>
          <div v-for="item in imageTypeList" :key="item.imageType">
            <el-form-item :label="item.imageName">
              <div class="flex">
                <template v-if="item.imgUrl">
                  <div
                    v-for="(item1, index) in item.imgUrl.split(',')"
                    :key="index"
                  >
                    <div>
                      <el-image
                        v-if="item1"
                        style="width: 178px; height: 178px; margin-right: 10px"
                        :src="item1"
                        fit="scale-down"
                        :preview-src-list="
                          !item.imgUrl ? [] : item.imgUrl.split(',')
                        "
                        @click="solveRefresh()"
                      />
                      <div v-if="item1" style="text-align: center">
                        <el-button
                          type="text"
                          @click="goDeleteImage(index, item.imageType)"
                        >
                          删除
                        </el-button>
                      </div>
                    </div>
                  </div>
                </template>
                <template>
                  <do-upload-jpg
                    ref="douploadJpg"
                    :action="''"
                    list-type="picture-card"
                    :show-file-list="false"
                    :file-list="fileList"
                    class="uploadStyle"
                    @done="(vals) => ondone(vals, item.imageType)"
                  >
                    <i slot="default" class="el-icon-plus" />
                  </do-upload-jpg>
                </template>
              </div>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <div class="title">
        <div class="titleType">医生简介</div>
      </div>
      <el-form-item label="擅长:">
        <el-input
          v-model="form.speciality"
          placeholder="请输入医生擅长"
          style="width: 50%; margin-right: 20px"
          type="textarea"
          :rows="5"
          maxlength="200"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="简介:">
        <el-input
          v-model="form.introduction"
          placeholder="请输入简介"
          style="width: 50%; margin-right: 20px"
          type="textarea"
          :rows="5"
          maxlength="200"
          show-word-limit
        />
      </el-form-item>

      <div v-for="item in jianList" :key="item.imageType">
        <el-form-item ref="resumeUrl" :label="item.imageName" prop="resumeUrl">
          <div class="flex warp">
            <template v-if="item.imgUrl">
              <div
                v-for="(item1, index) in item.imgUrl.split(',')"
                :key="index"
              >
                <div>
                  <el-image
                    v-if="item1"
                    style="
                      width: 178px;
                      height: 178px;
                      margin-right: 10px;
                      background: #fff;
                    "
                    :src="item1"
                    fit="scale-down"
                    :preview-src-list="
                      !item.imgUrl ? [] : item.imgUrl.split(',')
                    "
                    @click="solveRefresh()"
                  />
                  <div v-if="item1" style="text-align: center">
                    <el-button
                      type="text"
                      @click="goDeleteImage(index, item.imageType)"
                    >
                      删除
                    </el-button>
                  </div>
                </div>
              </div>
            </template>
            <template
              v-if="
                (item.imgUrl && item.imgUrl.split(',').length !== 10) ||
                !item.imgUrl
              "
            >
              <do-upload-jpg
                ref="douploadJpg"
                :action="''"
                list-type="picture-card"
                :show-file-list="false"
                :file-list="fileList"
                class="uploadStyle"
                @done="(vals) => ondone(vals, item.imageType)"
              >
                <i slot="default" class="el-icon-plus" />
              </do-upload-jpg>
            </template>
          </div>
        </el-form-item>
      </div>

      <div class="btn">
        <el-button type="primary" @click="saveData"> 保存 </el-button>
        <template v-if="isView && form.authenticationStatus == 'AUTH_SUCCESS'">
          <el-button type="primary" @click="openPermissions()">
            开通问诊权限
          </el-button>
        </template>

        <el-button @click="$router.back()"> 返回 </el-button>
      </div>
    </el-form>
    <el-dialog
      :title="titleType + '个人经历信息'"
      :visible.sync="dialogVisible"
      width="45%"
    >
      <div>
        个人经历列表:
        <el-button type="primary" @click="addData()"> 追加 </el-button>
      </div>
      <div v-for="(item, index) in experienceList" :key="index">
        <el-form
          v-if="experienceList.length > 0"
          ref="item"
          label-position="right"
          :model="item"
          label-width="160px"
          @submit.native.prevent
        >
          <!-- <h3 style="margin-bottom:20px;text-align: center;">确定核销当前预约单吗？</h3> -->
          <el-form-item label="一段工作经历开始时间:" prop="weDateStart">
            <el-date-picker
              v-model="item.weDateStart"
              value-format="yyyy-MM-dd"
              type="date"
              style="width: 50%"
              placeholder="选择日期"
            />
          </el-form-item>
          <el-form-item label="一段工作经历结束时间:" prop="weDateEnd">
            <el-date-picker
              v-model="item.weDateEnd"
              value-format="yyyy-MM-dd"
              type="date"
              style="width: 50%"
              placeholder="选择日期"
            />
          </el-form-item>
          <el-form-item label="单位名称:" prop="weEmployer">
            <el-input
              v-model="item.weEmployer"
              v-check-permission
              placeholder="医疗机构名称:"
              style="width: 50%"
              maxlength="50"
              @keyup.enter.native="saveOrgan()"
            />
          </el-form-item>
          <el-form-item label="专业技术职务任职资格:">
            <el-select v-model="item.weTitle" style="width: 40%">
              <el-option
                v-for="item2 in allProfessionalList"
                :key="item2.professionalTitleCode"
                :label="item2.professionalTitleName"
                :value="item2.professionalTitleName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="证明人:">
            <el-input
              v-model="item.weCertifier"
              v-check-permission
              style="width: 50%"
            />
          </el-form-item>
          <el-button
            type="danger"
            style="margin-left: 100px"
            @click="delData(index)"
          >
            删除
          </el-button>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveExperience()">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择权限开通"
      :visible.sync="permissionDialogVisible"
      width="40%"
    >
      <el-form
        ref="auth"
        :model="auth"
        label-width="110px"
        @submit.native.prevent
      >
        <el-form-item>
          <el-checkbox v-model="auth.healthConsult"> 健康咨询 </el-checkbox>
          <el-checkbox v-model="auth.textConsult"> 图文问诊 </el-checkbox>
          <el-checkbox v-model="auth.videoConsult"> 视频问诊 </el-checkbox>
          <el-checkbox v-model="auth.writePrescription"> 开方 </el-checkbox>
          <el-checkbox v-model="auth.auditPrescription"> 审方 </el-checkbox>
          <el-checkbox v-model="auth.furtherConsult"> 专病服务 </el-checkbox>
          <el-checkbox v-model="auth.invitation"> 线下邀约 </el-checkbox>
          <el-checkbox v-model="auth.immediateVideoConsult">
            即时视频问诊
          </el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="permissionDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="savePermission()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DoUpload from '@/components/upload';
import DoUploadJpg from '@/components/uploadJpg';
// import DoUploadResume from '@/components/uploadResume';
import { mapActions, mapState } from 'vuex';
import { checkIdCard, checkMobile } from '@/utils/verificationRule';
import {
  doctorPracticeLevelList,
  isNoList,
  sexList,
  healthConditionList,
  educationList,
} from '@/utils/component/publicList';
import moment from 'moment';
export default {
  name: 'Details',
  components: {
    DoUpload,
    DoUploadJpg,
    // DoUploadResume
  },
  data() {
    this.info = {
      id: '',
      weEmployer: '',
      weDateEnd: '',
      weDateStart: '',
      weTitle: '',
    };
    return {
      allProfessionalList: [], // 所有职称列表
      doctorPracticeLevelList: doctorPracticeLevelList, // 医生执业级别
      isNoList: isNoList, // 是否同意条款协议
      healthConditionList: healthConditionList, // 健康列表
      educationList: educationList, // 学历列表
      sexList: sexList, // 性别字典
      districtList: [], // 行政区列表
      cityList: [],
      nationList: [], // 民族列表
      dialogVisible: false,
      disabled: false,
      dialogVisibleForResume: false,
      dialogImageUrlForRes: '',
      titleType: '', // 新增/修改个人经历信息
      permissionDialogVisible: false, // 权限开通
      isChangePermission: false, // 是否点击提交处方权限
      auth: {
        healthConsult: false,
        textConsult: false,
        videoConsult: false,
        writePrescription: false,
        auditPrescription: false,
        invitation: false,
        furtherConsult: false,
        immediateVideoConsult: false,
      },
      form: {
        practicingDoctorImageUrl: '',
        id: '',
        occupationCode: '',
        occupationName: '',
        doctorName: '',
        parentDeptCode: '',
        parentDeptName: '',
        teamName: '',
        teamCode: '',
        idCard: '',
        professionalTitleName: '',
        idCardFrontImageUrl: '',
        introduction: '',
        speciality: '',
        idCardBackImageUrl: '', // 身份证反面
        practicingDoctomrIageUrl: '', // 执业医生证图片
        otherImageUrl: '', // 最高职称
        doctorCertificateImageUrl: '', // 医生资格证图片
        deptName: '',
        educationUrl: '', // 最高学历照片
        professionalTitleCode: '',
        deptCode: '',
        mobile: '',
        orgCode: '',
        orgName: '',
        photoUrl: '',
        resumeUrl: '', // 简历照片
        regulatory: {
          hosConfirmFlag: true,
          docPhoto: '',
          // docEdu:'',
          hosOpinionDate: '',
          agreeTerms: '是',
          pracNo: '',
          certNo: '', // 资格证号
          titleNo: '', // 职称证号
          pracLevel: '', // 医师执业级别
          orgName: '',
          orgCode: '',
          appraisalContent: '',
          docMultiSitedDateStart: '',
          hosOpinion: '',
          geCode: '1',
          geName: '',
          docBirthdate: '',
          docEdu: '',
          docPosition: '',
          pracRecDate: '',
          certRecDate: '',
          titleRecDate: '',
          pracType: '', // 医师执业类别
          qualifyOrNot: '是',
          pracScopeApproval: '', // 医师执业范围
          docMultiSitedDateEnd: '',
          presRecordFlag: true,
          recordFlag: true,
          nationCode: '',
          docPostalCode: '',
          provinceCode: '',
          provinceName: '',
          cityCode: '',
          cityName: '',
          healthCondition: '健康',
          punishContent: '',
          otherContent: '',
          workExperienceList: '',
          workInstName: '',
          workInstCode: '',
          titleCertList: '',
          docMultiSitedRecordList: '',
          employFilesList: '',
        },
      },
      experienceList: [this.info],
      parentDepartmentList: [], // 一级科室列表
      departmentList: [], // 二级科室列表
      rules: {
        idCard: [{ required: true, validator: checkIdCard, trigger: 'blur' }],
        doctorName: [
          {
            required: true,
            message: '请输入医生姓名',
            trigger: 'blur',
          },
        ],
        teamCode: [
          {
            required: true,
            message: '请选择所属医生团队',
            trigger: 'change',
          },
        ],
        orgCode: [
          {
            required: true,
            message: '请选择就职医院',
            trigger: 'change',
          },
        ],
        mobile: [{ required: true, validator: checkMobile, trigger: 'blur' }],
        parentDeptCode: [
          { required: true, message: '请选择科室', trigger: 'change' },
        ],
        professionalTitleCode: [
          { required: true, message: '请选择职称', trigger: 'change' },
        ],
        deptName: [
          { required: true, message: '请选择科室', trigger: 'change' },
        ],
        educationUrl: [
          {
            required: true,
            validator: this.validateEducationUrl,
            trigger: 'change',
          },
        ],
        resumeUrl: [
          {
            required: true,
            validator: this.validateResumeUrl,
            trigger: 'change',
          },
        ],
      },
      imageTypeList: [],
      jianList: [], // 简历图片列表
      fileList: [],
    };
  },
  computed: {
    ...mapState('doctorTeam', {
      doctorTeamList: (state) => state.allDoctorList,
    }),
    ...mapState('basicDictionary', {
      allOrganList: (state) => state.allOrganList,
      professionalList: (state) => state.professionalList,
      occupationList: (state) => state.occupationList,
      allDepartmentList: (state) => state.allDepartmentList,
    }),
    ...mapState('doctorManage', {
      firstPracticeOrganList: (state) => state.firstPracticeOrganList,
    }),
    isView() {
      return Number(this.$route.query.type) === 1;
    },
  },
  watch: {
    // 'form.occupationCode':  function(value) {
    //   this.form.professionalTitleCode = this.isView ? this.form.professionalTitleCode : '';
    //   this.occupationList.forEach( item => {
    //     if(value === item.occupationCode) {
    //       this.form.occupationName = item.occupationName;
    //       this.getProfessionalList(value);
    //     }
    //   });
    // },
    'form.professionalTitleCode': function (value) {
      if (value) {
        this.professionalList.forEach((item) => {
          if (value === item.professionalTitleCode) {
            this.form.professionalTitleName = item.professionalTitleName;
            this.getProfessionalList(value);
          }
        });
      }
    },
    'form.parentDeptCode': function (value) {
      this.parentDepartmentList.forEach((item) => {
        if (item.deptCode === value) {
          this.form.parentDeptName = item.deptName;
          this.departmentList = JSON.parse(JSON.stringify(item.children));
        }
      });
    },
    'form.deptName': function (value) {
      this.departmentList.forEach((item) => {
        if (item.deptName === value) {
          this.form.deptCode = item.deptCode;
        }
      });
    },
    'form.teamCode': function (value) {
      this.doctorTeamList.forEach((item) => {
        if (item.teamCode === value) {
          this.form.teamName = item.teamName;
        }
      });
    },
    'form.orgCode': function (value) {
      this.allOrganList.forEach((item) => {
        if (item.orgCode === value) {
          this.form.orgName = item.orgName;
        }
      });
    },
    'form.regulatory.geCode': function (value) {
      this.sexList.forEach((item) => {
        if (item.geCode === value) {
          this.form.regulatory.geName = item.geName;
        }
      });
    },
    'form.regulatory.nationCode': function (value) {
      this.nationList.forEach((item) => {
        if (item.code === value) {
          this.form.regulatory.nationName = item.name;
        }
      });
    },
    'form.regulatory.workInstCode': function (value) {
      this.firstPracticeOrganList.forEach((item) => {
        if (item.orgCode === value) {
          this.form.regulatory.workInstName = item.orgName;
        }
      });
    },
    'form.regulatory.orgCode': function (value) {
      this.firstPracticeOrganList.forEach((item) => {
        if (item.orgCode === value) {
          this.form.regulatory.orgName = item.orgName;
        }
      });
    },
    'form.regulatory.provinceCode': function (value) {
      this.districtList.forEach((item) => {
        if (item.code === value) {
          this.form.regulatory.provinceName = item.name;
          this.getCityList(value);
        }
      });
    },
    'form.regulatory.cityCode': function (value) {
      this.cityList.forEach((item) => {
        if (item.code === value) {
          this.form.regulatory.cityName = item.name;
        }
      });
    },
    'form.idCard': function (value) {
      this.getBirthdayFromIdCard(value);
    },
    'form.regulatory.pracScopeApproval': function (value) {
      this.changeDeptName(value);
    },
  },
  mounted() {
    this.getAllOrgan();
    this.getTeamList();
    this.getOccupationList();
    this.getDepartment();
    this.imageManage();
    this.getNationList();
    this.getAdministrativeList();
    this.getPracticeOrganList();
    this.getAllProfessionalList();
    this.getOrderDetail();
    this.changeDeptName();
    this.resumeImgManage();
  },
  methods: {
    ...mapActions('doctorManage', [
      'queryById',
      'edit',
      'audit',
      'regulatoryOrganList',
      'consultationGet',
    ]),
    ...mapActions('basicDictionary', [
      'organQueryList',
      'occupationQueryList',
      'professionalQueryList',
      'deptQueryList',
      'dicQueryList',
    ]),
    ...mapActions('doctorTeam', ['queryList']),
    // 编辑查详情
    getOrderDetail() {
      if (this.isView) {
        const param = {
          id: this.$route.query.id,
        };
        this.queryById(param).then((res) => {
          const { data } = res;
          data.regulatory = !data.regulatory
            ? {
                hosOpinionDate: '',
                agreeTerms: '是',
                pracNo: '',
                certNo: '', // 资格证号
                titleNo: '', // 职称证号
                pracLevel: '', // 医师执业级别
                orgName: '',
                orgCode: '',
                appraisalContent: '',
                docMultiSitedDateStart: '',
                hosOpinion: '',
                geCode: '',
                geName: '',
                docBirthdate: '',
                docEdu: '',
                docPosition: '',
                pracRecDate: '',
                certRecDate: '',
                titleRecDate: '',
                pracType: '',
                qualifyOrNot: '是',
                pracScopeApproval: this.form.deptName,
                // pracScopeApproval: '',

                docMultiSitedDateEnd: '',
                presRecordFlag: true,
                recordFlag: true,
                nationCode: '',
                docPostalCode: '',
                provinceCode: '',
                provinceName: '',
                cityCode: '',
                titleCertList: '',
                cityName: '',
                healthCondition: '健康',
                punishContent: '',
                otherContent: '',
                workExperienceList: '',
                workInstName: '',
                workInstCode: '',
                hosConfirmFlag: true,
                docPhoto: '',
                docMultiSitedRecordList: '',
              }
            : data.regulatory;
          this.form = JSON.parse(JSON.stringify(data));
          (this.auth.auditPrescription = data.auditPrescription),
            (this.auth.writePrescription = data.writePrescription),
            (this.auth.invitation = data.invitation),
            (this.form.regulatory.workExperienceList = this.form.regulatory
              .workExperienceList
              ? JSON.stringify(this.form.regulatory.workExperienceList)
              : '');
          this.experienceList = data.regulatory.workExperienceList
            ? data.regulatory.workExperienceList
            : [this.info];
          this.getPermissionList(data.id);
          this.getProfessionalList(this.form.occupationCode);
          this.imageManage();
          this.resumeImgManage();
        });
      }
    },
    // 查询医生视频、图文、健康咨询权限开通
    getPermissionList(id) {
      this.consultationGet({ id: id }).then((res) => {
        if (res.data.setConsultationList.length > 0) {
          res.data.setConsultationList.forEach((item) => {
            if (item.consultationType === '108') {
              this.auth.healthConsult = item.isShow;
            } else if (item.consultationType === '4') {
              this.auth.videoConsult = item.isShow;
            } else if (item.consultationType === '1') {
              this.auth.textConsult = item.isShow;
            } else if (item.consultationType === '120') {
              this.auth.furtherConsult = item.isShow;
            } else if (item.consultationType === '401') {
              this.auth.immediateVideoConsult = item.isShow;
            }
          });
        }
      });
    },
    // 民族
    getNationList() {
      this.dicQueryList({ dicTypeCode: 'Nationality_01' }).then((res) => {
        this.nationList = res.data;
      });
    },
    // 行政区划
    getAdministrativeList() {
      this.dicQueryList({ dicTypeCode: 'District_01 ' }).then((res) => {
        this.districtList = res.data;
      });
    },
    // 查询相应城市
    getCityList(code) {
      this.dicQueryList({ dicTypeCode: code }).then((res) => {
        this.cityList = res.data;
      });
    },
    getPracticeOrganList() {
      this.regulatoryOrganList({});
    },
    async getDepartment() {
      await this.deptQueryList();
      console.log(this.allDepartmentList, 'allDepartmentList');
      this.listToTree(this.allDepartmentList);
    },
    // 改变一级科室的选择,清空二级科室选中的内容
    changeParentDeptCode() {
      // 清空二级科室的id及数据
      // this.form.parentDeptCode = ''
      this.form.deptCode = '';
      this.departmentList = [];
    },
    changeDeptName(index) {
      this.form.regulatory.pracScopeApproval = index;
    },
    // 职称列表
    getProfessionalList(data) {
      this.professionalQueryList({ occupationCode: data });
    },
    // 查询所有职称列表
    getAllProfessionalList() {
      this.professionalQueryList({}).then((res) => {
        this.allProfessionalList = res.data;
      });
    },
    // 职业列表
    getOccupationList() {
      this.occupationQueryList();
    },
    // 查询团队列表
    getTeamList() {
      this.queryList({});
    },
    // // 学历自定义规则
    validateEducationUrl(rule, value, callback) {
      console.log('validateEducationUrl', rule, value);
      if (!this.form.educationUrl) {
        callback(new Error('请上传学历照片'));
      } else {
        callback();
      }
    },
    // 简历自定义规则
    validateResumeUrl(rule, value, callback) {
      console.log('validateResumeUrl', rule, value);
      if (!this.form.resumeUrl) {
        callback(new Error('请上传简历照片,最多10张'));
      } else {
        callback();
      }
    },
    // 头像上传回调
    ondone(vals, type) {
      const len = vals.length;
      console.log('dayin长度0000', len);
      const imageLength = this.form.resumeUrl
        ? this.form.resumeUrl.split(',').length
        : 0;
      const tempLen = type === 'resumeImage' ? imageLength : len;
      if (vals && tempLen < 10) {
        console.log(
          'imageLengthwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww',
          imageLength,
          type
        );

        switch (type) {
          case 'photo':
            this.form.photoUrl = vals[len - 1];
            break;

          case 'idCardFrontImage':
            this.form.idCardFrontImageUrl = vals[len - 1];
            break;
          case 'idCardBackImage':
            this.form.idCardBackImageUrl = vals[len - 1];
            break;
          case 'educationImage':
            this.form.educationUrl = vals[len - 1];
            this.$refs.form.validateField('educationUrl');
            this.$forceUpdate();
            break;
          case 'docPhoto':
            this.form.regulatory.docPhoto = vals[len - 1];
            break;
          case 'practicingDoctorImageUrl':
            this.form.practicingDoctorImageUrl =
              this.form.practicingDoctorImageUrl + vals[len - 1] + ',';
            this.imageManage();
            break;
          case 'doctorCertificateImageUrl':
            this.form.doctorCertificateImageUrl =
              this.form.doctorCertificateImageUrl + vals[len - 1] + ',';
            this.imageManage();
            break;
          case 'otherImageUrl':
            this.form.otherImageUrl =
              this.form.otherImageUrl + vals[len - 1] + ',';
            this.imageManage();
            break;
          case 'titleCertList':
            this.form.regulatory.titleCertList =
              this.form.regulatory.titleCertList + vals[len - 1] + ',';
            this.imageManage();
            break;
          case 'docMultiSitedRecordList':
            this.form.regulatory.docMultiSitedRecordList =
              this.form.regulatory.docMultiSitedRecordList +
              vals[len - 1] +
              ',';
            this.imageManage();
            break;
          case 'employFilesList':
            // this.form.regulatory.employFilesList =
            //   this.form.regulatory.employFilesList + vals[len - 1] + ',';
            this.form.regulatory.employFilesList = `${
              this.form.regulatory.employFilesList
                ? `${this.form.regulatory.employFilesList},`
                : ''
            }${vals[len - 1]}`;

            this.imageManage();
            break;
          case 'resumeImage':
            // if(vals.length === 1) {
            //   console.log('打印长度', vals.length);
            //   this.form.resumeUrl = vals[len - 1 ];
            // }else{
            console.log('我进到下边啦打印这个长度', vals.length);
            this.form.resumeUrl = `${
              this.form.resumeUrl ? `${this.form.resumeUrl},` : ''
            }${vals[len - 1]}`;
            console.log('dayin验证', this.form.resumeUrl);
            this.$refs.form.validateField('resumeUrl');
            // }

            console.log(vals, type, '上传个锤子图片');
            console.log('打印简历图片', this.form.resumeUrl);
            this.resumeImgManage();

            break;

          default:
            break;
        }
      }
    },
    // 删除图片
    goDeleteImage(index, imageType) {
      this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          switch (imageType) {
            case 'practicingDoctorImageUrl':
              var list = this.form.practicingDoctorImageUrl.split(',');
              list.splice(index, 1);
              this.$set(this.form, 'practicingDoctorImageUrl', list.join(','));
              this.imageManage();
              break;
            case 'doctorCertificateImageUrl':
              var list1 = this.form.doctorCertificateImageUrl.split(',');
              list1.splice(index, 1);
              this.$set(
                this.form,
                'doctorCertificateImageUrl',
                list1.join(',')
              );
              this.imageManage();
              break;
            case 'otherImageUrl':
              var list2 = this.form.otherImageUrl.split(',');
              list2.splice(index, 1);
              this.$set(this.form, 'otherImageUrl', list2.join(','));
              this.imageManage();
              break;
            case 'resumeImage':
              console.log('打印这个index', index);
              console.log('对!!! 就是我', this.form.resumeUrl);
              var list6 = this.form.resumeUrl.split(',');
              list6.splice(index, 1);
              console.log('打印list6', list6);

              this.$set(this.form, 'resumeUrl', list6.join(','));
              console.log('111111111111111111', this.form.resumeUrl);
              this.resumeImgManage();

              break;
            case 'titleCertList':
              var list3 = this.form.regulatory.titleCertList.split(',');
              list3.splice(index, 1);
              this.$set(this.form.regulatory, 'titleCertList', list3.join(','));
              this.imageManage();
              break;
            case 'docMultiSitedRecordList':
              var list4 =
                this.form.regulatory.docMultiSitedRecordList.split(',');
              list4.splice(index, 1);
              this.$set(
                this.form.regulatory,
                'docMultiSitedRecordList',
                list4.join(',')
              );
              this.imageManage();
              break;
            case 'employFilesList':
              var list5 = this.form.regulatory.employFilesList.split(',');
              list5.splice(index, 1);
              this.$set(
                this.form.regulatory,
                'employFilesList',
                list5.join(',')
              );
              this.imageManage();
              break;

            default:
              break;
          }
        })
        .catch(() => {});
    },

    // 编辑情况下保存
    saveData() {
      const { form, isChangePermission, auth } = this;
      this.$refs['form'].validate((valid, object) => {
        console.log(
          '编辑保存========>',
          valid,
          '-------->',
          object,
          '-------->',
          form
        );
        if (valid) {
          const param = {
            ...JSON.parse(JSON.stringify(form)),
          };
          param.regulatory.workExperienceList = param.regulatory
            .workExperienceList
            ? JSON.parse(form.regulatory.workExperienceList)
            : [];
          if (isChangePermission) {
            param.auth = auth;
          }
          if (!this.isView) {
            param.doctorUserId = 'doctor-manage';
            delete param.id;
          }
          this.edit(param).then((res) => {
            if (res.code === 0) {
              this.$message.success(
                this.isView ? '编辑医生成功' : '新增医生成功'
              );
              this.$router.go(-1);
            }
          });
        } else {
          return false;
        }
      });
    },
    // el-image点击图片页面卡顿问题
    solveRefresh() {
      document.body.style = '';
    },
    // 提取出生年月日
    getBirthdayFromIdCard(idCard) {
      var birthday = '';
      if (idCard !== null && idCard !== '') {
        if (idCard.length === 15) {
          birthday = '19' + idCard.substr(6, 6);
        } else if (idCard.length === 18) {
          birthday = idCard.substr(6, 8);
        }

        this.form.regulatory.docBirthdate = birthday.replace(
          /(.{4})(.{2})/,
          '$1-$2-'
        );
      }

      // return birthday
    },
    // 获取所有机构医院
    getAllOrgan() {
      this.organQueryList({});
    },
    // 图片管理method
    imageManage() {
      this.imageTypeList = [];
      const { form } = this;
      console.log(form, 'form');
      this.imageTypeList.push({
        imageType: 'practicingDoctorImageUrl',
        imgUrl: form.practicingDoctorImageUrl,
        imageName: '医师执业证书',
      });
      this.imageTypeList.push({
        imageType: 'doctorCertificateImageUrl',
        imgUrl: form.doctorCertificateImageUrl,
        imageName: '医/药师资格证书',
      });
      this.imageTypeList.push({
        imageType: 'otherImageUrl',
        imgUrl: form.otherImageUrl,
        imageName: '其它证书',
      });
      this.imageTypeList.push({
        imageType: 'titleCertList',
        imgUrl: form.regulatory.titleCertList,
        imageName: '医师/药师职称证文件列表',
      });
      this.imageTypeList.push({
        imageType: 'docMultiSitedRecordList',
        imgUrl: form.regulatory.docMultiSitedRecordList,
        imageName: '医师执业备案文件列表',
      });
      this.imageTypeList.push({
        imageType: 'employFilesList',
        imgUrl: form.regulatory.employFilesList,
        imageName: '互联网医院聘任合同列表',
      });

      console.log(this.imageTypeList, '11打印这个list');
    },
    // 简历图片管理
    resumeImgManage() {
      this.jianList = [];
      const { form } = this;
      console.log(form, 'form');
      this.jianList.push({
        imageType: 'resumeImage',
        imgUrl: form.resumeUrl,
        imageName: '简历',
      });
      console.log('打印这个jianlist', this.jianList);
    },
    // 科室列表转树
    listToTree(oldArr) {
      this.parentDepartmentList = [];
      oldArr.forEach((element) => {
        const parentDeptCode = element.parentDeptCode
          ? element.parentDeptCode
          : null;
        if (parentDeptCode !== null) {
          oldArr.forEach((ele) => {
            if (ele.deptCode === parentDeptCode) {
              // 当内层循环的ID== 外层循环的parendId时，（说明有children），需要往该内层id里建个children并push对应的数组；
              if (!ele.children) {
                ele.children = [];
              }
              ele.children.push(element);
            }
          });
        }
      });

      oldArr = oldArr.filter(
        (ele) => !ele.parentDeptCode || ele.parentDeptCode === null
      ); // 这一步是过滤，按树展开，将多余的数组剔除；
      this.parentDepartmentList = oldArr;
      console.log(this.parentDepartmentList, '2222233');
    },
    // 新增个人经历
    addInfoExperience() {
      this.dialogVisible = true;
      this.titleType = '新增';
    },
    // 编辑个人经历
    editorData(scope) {
      this.dialogVisible = true;
      this.titleType = '修改';
      this.form1 = scope.row;
    },
    addData() {
      this.info = {
        id: '',
        weEmployer: '',
        weDateEnd: '',
        weDateStart: '',
        weTitle: '',
      };
      this.experienceList.push(this.info);
    },
    delData(index) {
      this.experienceList.splice(index, 1);
    },
    saveExperience() {
      console.log(this.experienceList, '455555555');
      this.experienceList.forEach((item) => {
        item.weDateStart =
          item.weDateStart !== ''
            ? moment(item.weDateStart).format('YYYY-MM-DD')
            : '';
        item.weDateEnd =
          item.weDateEnd !== ''
            ? moment(item.weDateEnd).format('YYYY-MM-DD')
            : '';
      });
      this.form.regulatory.workExperienceList = JSON.stringify(
        this.experienceList
      );
      this.dialogVisible = false;
    },
    // 确定开通权限
    savePermission() {
      this.isChangePermission = true;
      this.permissionDialogVisible = false;
    },
    // 开通权限
    openPermissions() {
      this.permissionDialogVisible = true;
      console.log('---this', this);
      console.log('permissionDialogVisible--', this.permissionDialogVisible);
    },
    // 获取职业列表
    receiveProfessionList(value) {
      console.log(value, '1111111111111');
      this.form.professionalTitleCode = '';
      this.occupationList.forEach((item) => {
        if (value === item.occupationCode) {
          this.form.occupationName = item.occupationName;
          this.getProfessionalList(value);
        }
      });
    },
    // 获取二级科室列表
    receiveDeptmentList(value) {
      this.form.deptName = '';
      this.parentDepartmentList.forEach((item) => {
        if (item.deptCode === value) {
          this.form.parentDeptName = item.deptName;
          this.departmentList = JSON.parse(JSON.stringify(item.children));
        }
      });
    },
    // 简历上传成功
    // uploadSuccessResume(res, file) {
    //   console.log('简历上传成功打印参数', res, file);
    // },
    // // 简历上传相关方法
    // handleRemove(file) {
    //   console.log(file);
    // },
    // // 简历上传相关方法
    // handlePictureCardPreview(file) {
    //   this.dialogImageUrlForRes = file.url;
    //   this.dialogVisibleForResume = true;
    // },
    // // 简历上传相关方法
    // handleDownload(file) {
    //   console.log(file);
    // }
  },
};
</script>

<style lang="scss" scoped>
.formdata {
  padding: 20px;

  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.labelStyle {
  margin-right: 10px;
}

.title {
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
}

.btn {
  margin-left: 120px;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
  margin-right: 20px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  background-color: #f5f7fa;
  margin-right: 20px;
  line-height: 178px;
  text-align: center;
}

.flex {
  display: flex;

  &.warp {
    width: 1000px;
    flex-wrap: wrap;
  }
}
.width-96 {
  margin: 10px;
}
</style>
