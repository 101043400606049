import { render, staticRenderFns } from "./addDoctor.vue?vue&type=template&id=5a565afc&scoped=true"
import script from "./addDoctor.vue?vue&type=script&lang=js"
export * from "./addDoctor.vue?vue&type=script&lang=js"
import style0 from "./addDoctor.vue?vue&type=style&index=0&id=5a565afc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a565afc",
  null
  
)

export default component.exports